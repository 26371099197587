<template>
  <div class="inset-0 flex z-30" :class="height ? height : 'h-screen'">
    <div class="flex flex-col m-auto">
      <!-- <span class="m-auto"></span> -->
      <img
        class="w-48 my-6 mx-auto animlogo"
        :src="require('../assets/images/logo.png')"
        alt="Logomarca Colheita de Talentos"
      />
      <h2 class="m-auto text-2xl text-green-1001 font-bold">Carregando</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoaderAnimation',

  props: {
    height: {
      required: false,
      type: String
    }
  }
};
</script>

<style scoped>
.animlogo {
  animation: bounceIn 6s infinite;
  animation-timing-function: cubic-bezier(0.51, 1.93, 0.66, -1.61);
}
span {
  display: inline-block;
  width: 80px;
  height: 80px;
}
span:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #456a52;
  border-color: #456a52 transparent #456a52 transparent;
  animation: loading 1.2s linear infinite;
}
@keyframes bounceIn {
  0% {
    transform: scale(2);
    opacity: 1;
  }
  50% {
    transform: scale(1.8);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
