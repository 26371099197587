<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">
      <div class="flex mb-4 justify-between">
          <router-link
            class="bg-green-1000 text-white px-6 py-3 rounded rounded-md focus:outline-none"
            :to="{ name: 'list-vacancies' }"
          >
            <svg
              style="display: initial;"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            Voltar
          </router-link>
        </div>
      <div class="p-4 flex mb-2">
        <h1 class="text-3xl">Agendar entrevista: {{ vacancyTitle }}</h1>
      </div>
      <!-- STEPS -->
      <div class="w-full py-6">
        <div class="flex">
          <div class="w-1/4">
            <div class="relative mb-2">
              <div class="w-10 h-10 mx-auto bg-green-600 rounded-full text-lg text-white flex items-center">
                <span class="text-center text-white w-full">
                  <!-- <h1 class="text-white">1°</h1> -->
                  <i class="ri-file-list-line"></i>
                </span>
              </div>
            </div>
            <div class="text-xs text-center md:text-base">Em inscrição</div>
          </div>

          <div class="w-1/4">
            <div class="relative mb-2">
              <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div class="w-0 bg-green-500 py-1 rounded" style="width: 100%;"></div>
                </div>
              </div>

              <div class="w-10 h-10 mx-auto bg-green-600 rounded-full text-lg text-white flex items-center">
                <span class="text-center text-white w-full">
                  <svg class="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/>
                    <path d="M12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 8-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6 10.5l-2.939 1.545.561-3.272-2.377-2.318 3.286-.478L18 14l1.47 2.977 3.285.478-2.377 2.318.56 3.272L18 21.5z" fill="rgba(255,255,255,1)"/>
                  </svg>       
                </span>
              </div>
            </div>

            <div class="text-xs text-center md:text-base">Em Seleção</div>
          </div>

          <div class="w-1/4">
            <div class="relative mb-2">
              <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div class="w-0 bg-green-500 py-1 rounded" style="width: 100%;"></div>
                </div>
              </div>

              <div class="w-10 h-10 mx-auto bg-green-600 rounded-full text-lg text-white flex items-center">
                <span class="text-center text-gray-600 w-full">
                  <svg class="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z" fill="rgba(255,255,255,1)"/>
                  </svg>
                </span>
              </div>
            </div>

            <div class="text-xs text-center md:text-base">Agendar entrevista</div>
          </div>

          <div class="w-1/4">
            <div class="relative mb-2">
              <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div class="w-0 bg-green-500 py-1 rounded" style="width: 70%;"></div>
                </div>
              </div>

              <div class="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                <span class="text-center text-gray-600 w-full">
                  <svg class="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(75,85,99,1)"/>
                  </svg>
                </span>
              </div>
            </div>

            <div class="text-xs text-center md:text-base">Candidatos aprovados</div>
          </div>
        </div>
      </div>

      <!-- body -->
      <div class="p-4">
        <v-client-table
          ref="table"
          :data="participants"
          :columns="columns"
          :options="options"
        >
          <div class="flex justify-center" slot="actions" slot-scope="{ row }">
            <router-link
              v-if="row.status == 'Entrevistado(a)'"
              class="flex p-1 border-2 border-green-1002 w-max rounded-lg mr-4"
              :to="{
                name: 'interview',
                params: {
                  tipo: 'visualizar',
                  vacancy: $route.params.id,
                  participant: row.id
                }
              }"
              v-tooltip="{ content: 'Visualizar', placement: 'left' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M2 22a8 8 0 1 1 16 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm7.363 2.233A7.505 7.505 0 0 1 22.983 22H20c0-2.61-1-4.986-2.637-6.767zm-2.023-2.276A7.98 7.98 0 0 0 18 7a7.964 7.964 0 0 0-1.015-3.903A5 5 0 0 1 21 8a4.999 4.999 0 0 1-5.66 4.957z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </router-link>

            <router-link
              v-if="row.status == 'Selecionado(a)'"
              class="flex p-1 border-2 border-red w-max rounded-lg mr-4"
              :to="{
                name: 'interview',
                params: {
                  tipo: 'incluir',
                  vacancy: $route.params.id,
                  participant: row.id
                }
              }"
              v-tooltip="{ content: 'Agendar', placement: 'left' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M2 22a8 8 0 1 1 16 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm7.363 2.233A7.505 7.505 0 0 1 22.983 22H20c0-2.61-1-4.986-2.637-6.767zm-2.023-2.276A7.98 7.98 0 0 0 18 7a7.964 7.964 0 0 0-1.015-3.903A5 5 0 0 1 21 8a4.999 4.999 0 0 1-5.66 4.957z"
                  fill="#dc2626"
                />
              </svg>
            </router-link>

            <router-link
              v-if="row.status == 'Agendado(a)'"
              class="flex p-1 border-2 border-yellow w-max rounded-lg mr-4"
              :to="{
                name: 'interview',
                params: {
                  tipo: 'atualizar',
                  vacancy: $route.params.id,
                  participant: row.id
                }
              }"
              v-tooltip="{
                content: 'Entrevistar',
                placement: 'left'
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M2 22a8 8 0 1 1 16 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm7.363 2.233A7.505 7.505 0 0 1 22.983 22H20c0-2.61-1-4.986-2.637-6.767zm-2.023-2.276A7.98 7.98 0 0 0 18 7a7.964 7.964 0 0 0-1.015-3.903A5 5 0 0 1 21 8a4.999 4.999 0 0 1-5.66 4.957z"
                  fill="#fbbf24"
                />
              </svg>
            </router-link>
          </div>
        </v-client-table>
        <div class="flex mb-4 justify-end">
          <router-link
            class="bg-green-1000 justify-end text-white px-6 py-3 rounded rounded-md focus:outline-none"
            :to="{
              name: 'finalizing-vacancy',
              params: { id: this.$route.params.id }
            }"
          >
          <i class="ri-check-fill mr-1"></i>
            Finalização da vaga
          </router-link>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import axios from '@/utils/axios';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';

export default {
  name: 'ScheduleInterview',

  title() {
    return `${process.env.VUE_APP_NAME} | Agendar entrevista`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  props: {
    id: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      vacancyTitle: '',
      associateName: '',
      participants: [],
      columns: ['id', 'name', 'status', 'actions'],
      options: {
        sortable: true,
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          name: 'Nome',
          document: 'E-mail',
          resume: 'Currículo',
          actions: 'Ação',
          status: 'Status'
        },
        hiddenColumns: ['id'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count:
            '{from} - {to} de {count} registros|{count} registros|Um registro',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhum registro encontrado.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      }
    };
  },

  methods: {
    fecthParticipants() {
      this.isLoading = true;
      axios
        .get(`/api/participante/agendar-entrevista/${this.$route.params.id}`)
        .then(({ data }) => {
          data.participants.forEach(participant => {
            this.participants.push(participant);
          });
          this.isLoading = false;
        });
    },

    confirmSelection() {
      this.$confirm({
        message:
          'Você deseja confirmar esses candidatos para a próxima etapa do processo seletivo?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            //chamar função de Confirmar Seleção
            this.$toast.success('Candidatos selecionados com sucesso.');
          }
        }
      });
    },

    fetchVacancy() {
      axios
        .get(`/api/vagas/${this.$route.params.id}/vaga/`)
        .then(({ data }) => {
          this.vacancyTitle = data.data[0].office;
        });
    }
  },

  created: function() {
    this.fecthParticipants();
    this.fetchVacancy();
  }
};
</script>

<style scoped>
.unscheduled {
  color: #dc2626;
}
.border-red {
  border-color: #dc2626;
}
.scheduled {
  color: #fbbf24;
}
.border-yellow {
  border-color: #fbbf24;
}
.interviewed {
  color: rgba(0, 122, 111, 1);
}
</style>
